<template>
    <div class="signin position-relative">
        <TitleHeader :title="title" :link="showLogin?'transitionBack':'/home'" :hide_profile="true" @back="toggleSignup"/>
        <transition
            :name="transitionName"
            
        >
            
            <div v-if="!showLogin" class="position-absolute w-100 sign_wrap flex-column flex-center-center" :key="'login'">

                <div class="sign_box w-100">
                    <p class="small text-muted mb-3">아이디와 패스워드를 입력해주세요</p>
                    <div class="form-group mb-2">
                        <div class="input-group">
                            <input class="form-control border-gray-400 py-px-12" type="email" required placeholder="아이디" v-model="email">
                        </div>
                        <div class="alert alert-success small p-2 lh-1 mt-2" role="alert" v-if="email_certifi_send_chk">
                            <small>이메일로 인증번호를 발송했습니다.<br>아래 입력칸에 인증번호를 입력해 주십시오.</small>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <input class="form-control py-px-12" type="password" required placeholder="비밀번호" v-model="password" @keyup.enter="login">
                    </div>
                    <button @click="login" class="btn w-100 bg-main text-white d-flex py-px-12 align-items-center">
                        <span class="tit w-100">로그인</span>
                    </button>
                    <div class="w-100 d-flex py-3 align-items-center justify-content-between text-center">
                        <router-link to="/find-pw" class="">
                            <span class="tit small text-gray-600 w-100">비밀번호 찾기</span>
                        </router-link>
                        <span @click="GotoMove">
                            <span class="tit small text-gray-600 w-100">회원가입</span>
                        </span>
                    </div>
                </div>
                
                <!-- <div class="container mt-6">
                    <div class="flex-center-center py-3 position-relative">
                        <div class="border-bottom border-gray-400 w-100"></div>
                        <div class="position-absolute start-50 translate-middle top-50 line-inner-text bg-body px-3 small text-muted">SNS 계정으로 로그인</div>
                    </div>
                    <ul class="social_list d-flex justify-content-around align-items-center pt-3">
                        <li>
                            <a href="#" target="_blank">
                                <img src="@/assets/img/social/icon_kakao.png" alt="social_kakao">
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <img src="@/assets/img/social/icon_naver.png" alt="social_kakao">
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <img src="@/assets/img/social/icon_facebook.png" alt="social_kakao">
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <img src="@/assets/img/social/icon_google.png" alt="social_kakao">
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <img src="@/assets/img/social/icon_apple.png" alt="social_kakao">
                            </a>
                        </li>
                    </ul>
                </div> -->
            </div>
            <div v-else class="position-absolute w-100 px-3 h-100 d-flex justify-content-center flex-column" :key="'signup'">
                <div class="w-100">
                    <div class="mb-6">
                        <h2 class="mb-2">환영합니다,<br>회원님!</h2>
                        <p class="text-muted small mb-5">어떤 회원으로 시작할지 선택해주세요</p>
                        <router-link to="/signup" class="btn w-100 d-flex flex-column py-3 text-body-i mb-3 text-start bg-main">
                            <b class="fs-2 w-100">USER</b>
                            <small>유저로 시작하기</small>
                        </router-link>
                        <router-link to="/signup" class="btn w-100 border d-flex flex-column py-3 text-main mb-3 text-start border-main border-width-px-2">
                            <b class="fs-2 w-100">SMC</b>
                            <small>SMC로 시작하기</small>
                        </router-link>
                    </div>
                    
                    <div class="text-center">
                        <span class="text-muted small text-underline" @click="toggleSignup">이미 계정이 있어요</span>
                    </div>
                </div>
            </div>
        </transition>
    
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");
import TitleHeader from '@/components/common/TitleHeader.vue'
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '로그인 및 회원가입',
            email:'',
            password:'',
            email_certifi_send_chk:false,
            showLogin: false,
            transitionName: 'slide-left',
            auto_login : this.$store.state.auto_login
        }
    },
    methods:{
        GotoMove(){
            
            this.$router.push({path:'/signup'});
        },
        AutoLogin(){
            const auto_token = this.$store.state.auto_token;

            const body = {auto_token};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/login/SignInAuto',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);

                            const login = true;
                            const token = body.token;
                            const nick = body.nick
                            const email = body.email;
                            const auto_login = body.auto_login;
                            const auto_token = body.login_token;
                            const mem_info = { login, token,nick,email,auto_login,auto_token};
                            this.$store.dispatch('SETLOGIN', { mem_info }).then(async () => {
                                window.location.href = '/';
                            });
                        }else{
                            const auto_login = 'N';
                            const auto_token = '';
                            const mem_info = { auto_login,auto_token};
                            this.$store.dispatch('SETAUTOLOGIN', { mem_info }).then(async () => {
                                // window.location.href = '/';
                            });
                            // alert("정보를 확인해주세요.");
                        }
                    }
                }
            );
        },
        login(){
            
            const email = this.email;
            const password = this.password;

            const body = {email,password};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/login/SignIn',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);

                            const login = true;
                            const token = body.token;
                            const nick = body.nick
                            const auto_login = body.auto_login;
                            const auto_token = body.login_token;
                            const mem_info = { login, token,nick,email,auto_login,auto_token};
                            this.$store.dispatch('SETLOGIN', { mem_info }).then(async () => {
                                window.location.href = '/';
                            });
                        }else{
                            this.$alert("정보를 확인해주세요.");
                        }
                    }
                }
            );            
        },
        
        toggleLogin(){
            this.transitionName = 'slide-left'; 
            this.showLogin = true; 
        },
        toggleSignup(){
            this.transitionName = 'slide-right';
            this.showLogin = false;
        }
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
        this.$EventBus.$emit('HideNav')
        const auto_login = this.auto_login;

        if(auto_login =='Y'){
            this.AutoLogin();
        }
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
        this.$EventBus.$emit('ShowNav')
    }
}
</script>

<style lang="scss">
    .signin{
        overflow: hidden;
        height: 100vh;
        min-height: 500px;
        .sign_wrap{
            height: calc(100% - 60px);
        }
    }
    /* 앞으로 이동할 때 (좌측으로 슬라이드) */
    .slide-left-enter-active,
    .slide-left-leave-active {
        transition: transform 0.3s ease;
        position: absolute;
        width: 100%;
    }

    .slide-left-enter {
        transform: translateX(100%);
    }

    .slide-left-leave-to {
        transform: translateX(-100%);
    }

    /* 뒤로 이동할 때 (우측으로 슬라이드) */
    .slide-right-enter-active,
    .slide-right-leave-active {
        transition: transform 0.3s ease;
        position: absolute;
        width: 100%;
    }

    .slide-right-enter {
        transform: translateX(-100%);
    }

    .slide-right-leave-to {
        transform: translateX(100%);
    }

    .social_list{
        img{
            height: 44px;
            object-fit: cover;
        }
        li>a{
            display: block;
        }
    }
</style>