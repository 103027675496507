<template>
    <div class="mb-5 pb-5">
        <TitleHeader :title="title" />

        <div class="px-3 mb-4">
            <span class="small text-danger">* 최근 6개월간 문의 내역입니다.</span>
        </div>
        <div class="mx-3">
            <ul class="board_list small" v-if="inquiry&&inquiry.length>0">
                <li v-for="(item,index) in inquiry" :key="index">
                    <div class="txt_box d-flex w-100 align-items-center border-bottom py-3 pe-2" @click="open_inquiry_idx == item.code ? open_inquiry_idx=-1 : open_inquiry_idx = item.code">
                        <b class="me-2 pe-1">Q.</b>
                        <p class="tit ">{{item.title}} ({{item.date}}) <span v-if="item.answer !=''" class="badge border bg-dark">답변완료</span></p>
                        <i class="fal small ps-3 ms-auto" :class="{'fa-chevron-down':open_inquiry_idx != item.code, 'fa-chevron-up':open_inquiry_idx == item.code}"></i>
                    </div>
                    <div class="txt_box px-3 py-4 bg-gray-relative-100 border-bottom" v-if="open_inquiry_idx == item.code">
                        <h6 class="mb-2 text-info">Q.</h6>
                        <div><pre>{{item.memo}}</pre></div>
                        <div v-if="item.answer !=''">
                            <hr class="my-4">
                                <h6 class="mb-2 text-danger">A.</h6>
                                <pre>{{item.answer}}</pre>
                        </div>
                        
                    </div>
                </li>
            </ul>
            
            <div class="empty_data" v-else>
                <p class="text-center py-5">
                    문의 내역이 없어요.
                </p>
            </div> 
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
const CryptoJS = require("crypto-js");

export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '1:1 문의 답변 확인',

            inquiry: [
            ],

            search:null,

            // front ui
            open_inquiry_idx: -1,
        }
    },
    methods: {
        GetList(){
            // const search = this.search;
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/cs/GetInquiryList',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            
                            this.inquiry = body.list
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(async () => {
                                window.location.href = '/signin';
                            });
                        }
                    }
                }
            );
        },
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
        this.GetList()
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    }
}
</script>
