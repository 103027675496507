import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    theme: 'light',
    login: false,
    token : '',
    nick : '',
    email : '',
    show_header_nav: true,
    score_guide: true,
    auto_login : 'N',
    auto_token : '',
    show_terms : false,
  },
  plugins: [createPersistedState()],
  mutations: {
    SETLOGIN(state, mem_info) {
      
      state.login = true;
      state.token = mem_info.token;
      state.nick = mem_info.nick;
      state.email = mem_info.email;
      state.auto_login = mem_info.auto_login;
      state.show_terms = false;
      if(mem_info.auto_login =='Y'){
        state.auto_token = mem_info.auto_token;
      }else{
        state.auto_token = '';
      }
    },
    SETAUTOLOGIN(state, mem_info) {
      console.log(mem_info)
      state.auto_login = mem_info.auto_login;
      if(mem_info.auto_login =='Y'){
        state.auto_token = mem_info.auto_token;
      }else{
        state.auto_token = '';
      }
      
    },
    SETTERM(state, mem_info) {
      state.show_terms = mem_info.show_terms;
    },
    CLOSETERM(state) {
      state.show_terms = false;
    },
    SETLOGOUT(state) {
      state.token = '';
      state.login = false;
      state.nick = '';
      state.email = '';
      state.show_terms = false;
    },

    scoreGuide(state, value){
      state.score_guide = value;
    },
    loadSong(state,value){
      state.all_songs = value;
    },
    show_header_nav(state, value){
      state.show_header_nav = value;
    },
    moveForward(state, index) {
      if (index > 0 && index < state.reserved_song.length) {
        const temp = state.reserved_song[index];
        state.reserved_song.splice(index, 1);
        state.reserved_song.splice(index - 1, 0, temp);
      }
    },
    removeRecord(state, index){
      state.record_song.splice(index, 1);      
    },
    removeAlert(state, index){
      state.alert_list.splice(index, 1);      
    }
  },
  actions: {
    SETLOGIN({commit}, {mem_info}) {
      commit('SETLOGIN', mem_info);
    },
    SETLOGOUT ({commit}) {
      commit('SETLOGOUT');
    },
    CLOSETERM ({commit}) {
      commit('CLOSETERM');
    },
    SETTERM({commit}, {mem_info}) {
      commit('SETTERM', mem_info);
    },
    SETAUTOLOGIN({commit}, {mem_info}) {
      commit('SETAUTOLOGIN', mem_info);
    },
    
  },
  modules: {
  }
})
