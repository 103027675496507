<template>
    <div id="PlayBox" :class="{'vertical': screen_ratio_vertical, 'horizontal': !screen_ratio_vertical}">
        <div class="song_wrap position-relative" :class="{'lyric':status.lyric}">
            <!-- 노래 종료 후 자동으로 넘어가는 기능이 구현되면 지우기 -->
            <!-- <div class="btn position-absolute text-white" @click="$router.push('/play-score')" style="top:65%; right:15px; background-color: #78a6e9; z-index: 3">*점수 보기</div> -->

            <div class="song_box ratio ratio-1x1">
                <div>
                    <header class="d-flex justify-content-between align-items-center bg-dark text-white py-1 ps-3 pe-1">
                        <i class="fal fa-star h6 bookmark" :class="{'fw-bold': favorite, 'fw-light':!favorite}" @click="favoriteFn()"></i>
                        <small><span>{{artist}}</span> - <span>{{name}}</span></small>
                        <div @click="CloseSong()" >
                            <a href="javascript:void(0)" class="btn text-white">
                                <i class="far fa-times h5 fw-light"></i>
                            </a>
                            
                            <!-- <button class="btn text-white" @click="openBtm('sheet',status.sheet)"><img src="@/assets/img/icon/playlist.png" height="25" alt="" class=" invert-1"></button> -->
                            <!-- <router-link to="/" class="btn text-white">
                                
                            </router-link> -->
                        </div>
                    </header>
                    <div class="inner_header bg-dark text-white bg-opacity-25 py-2 d-flex px-2 d-flex align-items-center">
                        <div>
                            <div class="option_btn bg-dark bg-opacity-25 rounded-pill me-1 px-2" @click="select_pitch_mode = !select_pitch_mode">{{pitch}}</div>
                            <div class="drop_down_box" v-show="select_pitch_mode">
                                <div class="dimm" @click="select_pitch_mode = false"></div>
                                <ul class="drop_down bg-dark text-center rounded-3 small px-2 py-1 position-absolute mt-1">
                                    <li class="py-1" v-for="(item, index) in pitch_list" :key="index" @click="ClickPitch(item.code)">
                                        {{item.text}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="option_btn bg-dark bg-opacity-25 rounded-pill me-1 px-2" @click="select_interval_mode = !select_interval_mode">{{interval}}</div>
                            <div class="drop_down_box" v-show="select_interval_mode">
                                <div class="dimm" @click="select_interval_mode = false"></div>
                                <ul class="drop_down bg-dark text-center rounded-3 small px-2 py-1 position-absolute mt-1">
                                    <li class="py-1" v-for="(item, index) in interval_list" :key="index" @click="interval=item; select_interval_mode = false">
                                        {{item}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="status.repeatAB!==0">
                            <div class="option_btn bg-dark bg-opacity-25 rounded-pill me-1 px-2 d-flex" :class="{'half-bg':status.repeatAB===1, 'checked':status.repeatAB===2}">
                                <span class="rounded-circle text-dark">        
                                    <div class="repeat" id="repeat-ab" style="height:20px"></div>
                                </span>
                                <span class="ms-2" :class="{'text-danger':status.slow}">{{ status.repeatAB!==2?'RPT':status.repeatCount }}</span>
                            </div>
                        </div>
                        <div v-if="status.repeat!==0">
                            <div class="option_btn bg-dark bg-opacity-25 rounded-pill me-1 px-2">
                                <div class="repeat mx-auto" style="height:20px" id="repeat-all" v-show="status.repeat===1"></div>
                                <div class="repeat mx-auto" style="height:20px" id="repeat-one" v-show="status.repeat===2"></div>
                                <div class="repeat mx-auto" style="height:20px" id="repeat-random" v-show="status.repeat===3"></div>
                            </div>
                        </div>
                        <div class="ms-auto d-flex gap-1 align-items-center">
                            <div class="mic d-flex align-items-center gap-1 h-px-14 flex-grow-1">
                                <i class="fa-light fa-circle-exclamation fs-px-12 opacity-75" v-show="micSensitivity<=0"></i>
                                <i class="fa-solid fa-microphone d-block" :class="{'opacity-75':micSensitivity<=0}"></i>
                                <div
                                v-for="(color, index) in barColors"
                                :key="index"
                                :style="{ backgroundColor: color }"
                                class="w-px-3 rounded-1 h-px-14"
                                ></div>
                            </div>
                            <div class="option_btn rounded-pill me-1 py-0 px-2 hstack">
                                <!-- <img src="@/assets/img/note_icon.svg" alt="score" width="12"> -->
                                <span class="fw-bold fs-px-20 me-1">{{score}}</span><small>점</small>
                            </div>
                        </div>
                    </div>
                    <div class="inner_content">
                        <div class="lyrics">
                            <div class="line_box" v-if="count >1">
                                <span>
                                    <span class="back_str">{{ count }}</span>
                                </span>
                            </div>
                            <div class="line_box">
                                <span >
                                    <span :class="CheckLyric(index)" v-for="(item,index) in lyric" :key="`f_${index}`">{{ item }}</span>
                                    <!-- <div class="cover" :style="`width: ${lyric_color}%;`">
                                        <span class="front_str">{{lyric}}</span>
                                    </div>     -->
                                </span>
                            </div>
                            <div class="line_box">
                                <span>
                                    <span :class="CheckLyric2(idx2)" v-for="(item,idx2) in lyric2" :key="`s_${idx2}`">{{ item }}</span>
                                    <!-- <span class="back_str">{{lyric2}}</span>
                                    <div class="cover" :style="`width: ${lyric2_color}%;`">
                                        <span class="front_str">{{lyric2}}</span>
                                    </div> -->
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="inner_footer position-absolute bottom-0 start-0 text-white px-3 pb-4 d-flex justify-content-center align-items-center w-100">
                        <div class="jump_btn small bg-dark bg-opacity-25 rounded-circle text-center position-relative shadow" >
                            <i class="fas fa-play position-absolute top-50 start-50  translate-middle fs-px-26" v-if="!play" @click="ClickPlay()"></i>
                            <i class="fas fa-pause position-absolute top-50 start-50  translate-middle fs-px-26" v-else @click="ClickPause()"></i>
                        </div>
                        <!-- <div class="jump10_btn small bg-dark bg-opacity-25 rounded-pill px-3 py-1" @click="current_time-=10">
                            <i class="fal fa-chevron-double-left small me-1"></i>
                            <span>10</span>
                        </div>
                        <div class="jump_btn bg-danger rounded-circle text-center position-relative shadow" v-if="jump" @click="Jump()">
                            <small class="position-absolute top-50 start-50  translate-middle fw-bold">간주<br>점프</small>
                        </div>
                        <div class="jump10_btn small bg-dark bg-opacity-25 rounded-pill px-3 py-1" @click="current_time+=10">
                            <span>10</span>
                            <i class="fal fa-chevron-double-right small ms-1"></i>
                        </div> -->
                    </div>
                </div>
            </div>
            
            <!-- <div class="play_contrl_box position-absolute bottom-0 start-0 w-100 border-bottom d-flex">
                <input id="audio-runtime" type="range" :value="current_time" :max="audio_time" class="position-absolute top-0 start-0 w-100 pointer-events-none">
                <ul class="d-flex flex-grow-1 justify-content-around align-items-center mt-2 text-body-i">
                    <li class="item h5" @click="setting_open = !setting_open">
                        <i class="fas fa-sliders-v"></i>
                    </li>
                    <li class="item h5">
                        <i class="fas fa-backward"></i>
                    </li>
                    <li class="item h5">
                        <i class="fas fa-stop" v-if="play" @click="play=false"></i>
                        <i class="fas fa-play" v-else @click="play=true"></i>
                    </li>
                    <li class="item h5">
                        <i class="fas fa-pause"></i>
                    </li>
                    <li class="item h5">
                        <i class="fas fa-forward"></i>
                    </li>
                    <li class="item h5">
                        <i class="fas fa-step-forward"></i>
                    </li>
                </ul>
            </div> -->
        </div>
        <div class="bottom_box contain-bg-img w-100" :style="`background-image: url(${require('../../assets/img/betaopen.png')})`">
            <div class="status_btn_list">
                <button class="open_status btn btn-primary" :class="{'vertical':!screen_ratio_vertical}" @click="status_open = !status_open">
                    <i class="fal text-white" :class="{'fa-chevron-left':status_open&&screen_ratio_vertical, 'fa-chevron-right':!status_open&&screen_ratio_vertical, 'fa-chevron-up':!status_open&&!screen_ratio_vertical,'fa-chevron-down':status_open&&!screen_ratio_vertical}"></i>
                </button>
                <ul class="status_list" :class="{'open':status_open}">
                    <li>
                        <button class="btn bg-white text-dark" @click="connectAux()">
                            <i class="fal" :class="{'fa-microphone-slash':!status.connect_mic,'fa-microphone checked':status.connect_mic}"></i>
                        </button>
                    </li>
                    <li>
                        <button class="btn bg-white text-dark" @click="openBtm('base',status.base)" :class="{'checked':status.base}">
                            <i class="fal fa-drum"></i>
                        </button>
                    </li>
                    <li>
                        <button class="btn bg-white text-dark" :class="{'checked':status.rate}" @click="openBtm('rate',status.rate)">
                            <i class="far fa-heart-rate"></i>
                        </button>
                    </li>
                    <li>
                        <button class="btn bg-white text-dark" :class="{'half-bg':status.repeatAB===1, 'checked':status.repeatAB===2}" @click="repeatSec()">
                            <div class="repeat invert-1" id="repeat-ab"></div>
                        </button>
                    </li>
                    <li>
                        <button class="btn bg-white text-dark" :disabled="status.repeatAB!==2" :class="{'checked':status.slow}" @click="status.slow=!status.slow">
                            <i class="far fa-turtle"></i>
                        </button>
                    </li>
                    <li>
                        <button class="btn bg-white text-dark" @click="repeatFn()">
                            <div class="repeat invert-1" id="repeat-none" v-show="status.repeat===0"></div>
                            <div class="repeat invert-1" id="repeat-all" v-show="status.repeat===1"></div>
                            <div class="repeat invert-1" id="repeat-one" v-show="status.repeat===2"></div>
                            <div class="repeat invert-1" id="repeat-random" v-show="status.repeat===3"></div>
                        </button>
                    </li>
                    <li>
                        <button class="btn bg-white text-dark" :class="{'checked':status.lyric}" @click="openBtm('lyric',status.lyric)">
                            <i class="fal fa-indent h5 fw-light"></i>
                        </button>
                    </li>
                </ul>
            </div>
            <div class="bottom_box_item sheet_box d-flex align-items-center justify-content-center" v-if="status.sheet">
                악보 화면
            </div>
            <div class="bottom_box_item sheet_box d-flex align-items-center justify-content-center" v-if="status.base">
                드럼 화면
            </div>
            <div class="bottom_box_item sheet_box d-flex align-items-center justify-content-center" v-if="status.rate">
                피치 화면
            </div>
            <div class="bottom_box_item sheet_box d-flex align-items-center justify-content-center" v-if="status.lyric">
                <!-- 가사 화면 켰을 때 광고 없애기 위해 -->
            </div>
        </div>


        <div class="setting_box position-fixed max-w-px-768 top-0 start-0 w-100 mh-100vh" v-if="setting_open">
            <div class="dimm position-fixed max-w-px-768 top-0 start-0 w-100 h-100 bg-dark bg-opacity-25" @click="setting_open = false"></div>
            <div class="content position-fixed max-w-px-768 top-50 start-50 translate-middle">
                <div class="position-absolute bottom-100 end-0" @click="setting_open = false">닫기</div>
                <header>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="btn btn-sm text-white bg-black flex-shrink-0 mx-3 rounded-pill">초기화</div>
                        <div class="line_tab w-100">
                            <ul>
                                <li :class="{'on':setting_tab_idx == index}" v-for="(item,index) in ['음악','악기','악보']" :key="index" @click="setting_tab_idx = index">
                                    <a href="#">{{item}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </header>
                <div class="body bg-primary border-top">
                    <!-- 음악 -->
                    <div class="setting_music_box py-2" v-if="setting_tab_idx == 0">
                        <div class="d-flex flex-column">
                            <ul class="d-flex justify-content-around w-100 list1">
                                <li v-for="(item,index) in setting_music1" :key="index" class="d-flex justify-content-center flex-column text-center">
                                    <p>{{item.title}}</p>
                                    <p class="my-2 text-l-blue">{{item.value}}</p>
                                    <div class="input_box"><input type="range"  v-model="item.value"></div>
                                </li>
                            </ul>
                            <ul class="d-flex justify-content-around pt-4 border-top mt-4 pb-3">
                                <li v-for="(item,index) in setting_music2" :key="index" class="d-flex justify-content-center flex-column text-center">
                                    <p>{{item.title}}</p>
                                    <div class="h5 text-white text-opacity-75 arrow_box">
                                        <div @click="SettingMusic2_Down(index)" class="lg updwn_btn">
                                            <i class="fas fa-mars small" v-if="index==2"></i>
                                            <i class="fas fa-caret-down" v-else></i>
                                        </div>
                                        <span class="val_txt">{{item.value}}</span>
                                        <div @click="SettingMusic2_Up(index)" class="lg updwn_btn">
                                            <i class="fas fa-venus small" v-if="index==2"></i>
                                            <i class="fas fa-caret-up" v-else></i>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <!-- 악기 -->
                    <div class="setting_inst_box py-2" v-if="setting_tab_idx == 1">
                        <ul class="d-flex justify-content-around w-100 list1">
                            <li v-for="(item,index) in setting_inst" :key="index" class="d-flex justify-content-center flex-column text-center">
                                <p>{{item.title}}</p>
                                <p class="my-2 text-l-blue">{{item.value}}</p>
                                <div class="input_box"><input type="range" v-model="item.value"></div>
                            </li>
                        </ul>
                    </div>


                    <!-- 악보 -->
                    <div class="setting_sheet_box" v-if="setting_tab_idx == 2">
                        <div class="d-flex border-bottom">
                            <div class="w-50">
                                <div class="bg-black p-2">
                                    <div class="hstack justify-content-between align-items-center">
                                        <span class="text-warning">주악보</span>
                                        <div class="form-check form-switch p-0">
                                            <input class="form-check-input py-2" type="checkbox" role="switch" v-model="setting_sheet_main.active">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center flex-column text-center mt-2">
                                        <div class="text-white text-opacity-75 arrow_box mx-auto">
                                            <div class="updwn_btn" @click="SettingSheet_MainInst_Down()">
                                                <i class="fas fa-caret-down"></i>
                                            </div>
                                            <span class="val_txt lg">{{setting_sheet_main.list[setting_sheet_main.selected_idx]}}</span>
                                            <div class="updwn_btn" @click="SettingSheet_MainInst_Up()">
                                                <i class="fas fa-caret-up"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                <div class="d-flex justify-content-between text-center p-2">
                                    <div>옥타브</div>
                                    <div class="text-white text-opacity-75 arrow_box">
                                        <div class="updwn_btn" @click="SettingSheet_MainOctave_Down()"><i class="fas fa-caret-down" ></i></div>
                                        <span class="val_txt">{{setting_sheet_main.octave}}</span>
                                        <div class="updwn_btn" @click="SettingSheet_MainOctave_Up()"><i class="fas fa-caret-up" ></i></div>
                                    </div>
                                </div>
                                
                                <div class="d-flex justify-content-between text-center p-2 border-bottom">
                                    <div>이조</div>
                                    <div class="text-white text-opacity-75 arrow_box">
                                        <div class="updwn_btn" @click="SettingSheet_MainTranspo_Down()"><i class="fas fa-caret-down" ></i></div>
                                        <span class="val_txt">{{setting_sheet_main.transpo}}</span>
                                        <div class="updwn_btn" @click="SettingSheet_MainTranspo_Up()"><i class="fas fa-caret-up" ></i></div>
                                    </div>
                                </div>

                                <ul class="d-flex small justify-content-around py-2">
                                    <li v-for="(item,index) in ['A-SAX','T-SAX','A-REC']" :key="index">
                                        <small>{{item}}</small>
                                    </li>
                                </ul>
                            </div>

                            <div class="w-50 border-start">
                                <div class="bg-black p-2">
                                    <div class="hstack justify-content-between align-items-center">
                                        <span class="text-warning">보조악보</span>
                                        <div class="form-check form-switch p-0 ms-auto">
                                            <input class="form-check-input py-2" type="checkbox" role="switch" v-model="setting_sheet_sub.active">
                                        </div>
                                        <div class="small border px-1 rounded">
                                            <small><span class="small">FULL</span></small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center flex-column text-center mt-2">
                                        <div class="text-white text-opacity-75 arrow_box mx-auto">
                                            <div class="updwn_btn" @click="SettingSheet_SubInst_Down()"><i class="fas fa-caret-down" ></i></div>
                                            <span class="val_txt lg">{{setting_sheet_sub.list[setting_sheet_sub.selected_idx]}}</span>
                                            <div class="updwn_btn" @click="SettingSheet_SubInst_Up()"><i class="fas fa-caret-up" ></i></div>
                                        </div>
                                    </div>
                                </div>

                                
                                <div class="d-flex justify-content-between text-center p-2">
                                    <div>옥타브</div>
                                    <div class="text-white text-opacity-75 arrow_box">
                                        <div class="updwn_btn" @click="SettingSheet_SubOctave_Down()"><i class="fas fa-caret-down" ></i></div>
                                        <span class="val_txt">{{setting_sheet_sub.octave}}</span>
                                        <div class="updwn_btn" @click="SettingSheet_SubOctave_Up()"><i class="fas fa-caret-up"></i></div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between text-center p-2 border-bottom">
                                    <div>이조</div>
                                    <div class="text-white text-opacity-75 arrow_box">
                                        <div class="updwn_btn" @click="SettingSheet_SubTranspo_Down()"><i class="fas fa-caret-down" ></i></div>
                                        <span class="val_txt">{{setting_sheet_sub.transpo}}</span>
                                        <div class="updwn_btn"  @click="SettingSheet_SubTranspo_Up()"><i class="fas fa-caret-up"></i></div>
                                    </div>
                                </div>

                                
                            <ul class="d-flex small justify-content-around py-2">
                                <li v-for="(item,index) in ['A-SAX','T-SAX','A-REC']" :key="index">
                                    <small>{{item}}</small>
                                </li>
                            </ul>
                            </div>
                        </div>

                        <div class="">
                            <div class="d-flex">
                                <div class="w-50">
                                    <div class="d-flex justify-content-between text-center p-2 border-bottom h-100">
                                        <div>자막</div>
                                        <div class="text-white text-opacity-75 arrow_box">
                                            <div class="updwn_btn" @click="SettingSheet_SubSubtitle_Down()"><i class="fas fa-caret-down" ></i></div>
                                            <span class="val_txt lg">{{setting_sheet_subtitle.list[setting_sheet_subtitle.selected_idx]}}</span>
                                            <div class="updwn_btn" @click="SettingSheet_SubSubtitle_Up()"><i class="fas fa-caret-up" ></i></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-50 border-start">
                                    <div class="hstack justify-content-between align-items-center p-2 border-bottom h-100">
                                        <span class="">음표컬러</span>
                                        <div class="form-check form-switch p-0">
                                            <input class="form-check-input py-2" type="checkbox" role="switch" v-model="setting_sheet_note_color">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex">
                                <div class="w-50 border-start">
                                    <div class="hstack justify-content-between align-items-center p-2">
                                        <span class="">메트로놈</span>
                                        <div class="form-check form-switch p-0">
                                            <input class="form-check-input py-2" type="checkbox" role="switch" v-model="setting_sheet_metronome">
                                        </div>
                                    </div>
                                </div>
                                <div class="w-50 border-start">
                                    <div class="hstack justify-content-between align-items-center p-2">
                                        <span class="">악보진행바</span>
                                        <div class="form-check form-switch p-0">
                                            <input class="form-check-input py-2" type="checkbox" role="switch" v-model="setting_sheet_progress">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <b-modal v-model="guide" hide-header hide-footer centered size="sm">
            <div class="py-3">
                <h3 class="text-center mb-3 fw-bold">정확한 점수 확인</h3>
                <p class="mb-3 text-center">노래 점수는 1분 이상 노래를<br>불러야 받을 수 있습니다.</p>
            </div>
            <div class="d-flex gap-2 w-100 justify-content-center">
                <button class="btn btn-outline-main w-100" @click="noMore()">다시 보지않기</button>
                <button class="btn btn-main w-100" @click="ClickStart()">확인</button>
            </div>
        </b-modal>
        
        <b-modal v-model="exit_popup" hide-header hide-footer centered size="sm">
            <div class="py-3">
                <h3 class="text-center mb-3 fw-bold"></h3>
                <p class="mb-3 text-center">노래를 중단 하시겠습니까?</p>
            </div>
            <div class="d-flex gap-2 w-100 justify-content-center">
                <button class="btn btn-outline-main w-100" @click="ClosePage()">중단하기</button>
                <button class="btn btn-main w-100" @click="CancelExit()">계속하기</button>
            </div>
        </b-modal>

        <b-modal v-model="noAux" hide-header hide-footer centered size="sm">
            <div class="py-3">
                <h3 class="text-center mb-3 fw-bold">알림</h3>
                <p class="mb-3 text-center">마이크(폰잭)을 연결해 주세요.</p>
            </div>
            <div class="d-flex gap-2 w-100 justify-content-center">
                <button class="btn btn-primary" @click="noAux=false">확인</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");

export default {
    data(){
        return{
            exit_popup : false,
            guide:null,
            bookmark: false,
            micSensitivity:0,
            favorite : false,
            artist : '',
            name : '',
            // song:{
            //     reserved:false,
            //     favorite:false,
            //     type:'7080',
            //     popularity:60,
            //     genres:'ballad',
            //     id:0,
            //     artist:'왁스(WAX)',
            //     name:'화장을 고치고',
            //     art:'http://image.genie.co.kr/Y/IMAGE/IMG_ALBUM/015/025/133/15025133_1322534839359_1_600x600.JPG'
            // },

            jump: true,
            
            pitch: '',
            interval: 'B',
            score: 0,

            tempo_list: [
                {code : 'U',text : '올리기'},
                {code : 'D',text : '내리기'},
            ],
            pitch_list: [
                {code : 'U',text : '올리기'},
                {code : 'D',text : '내리기'},
            ],
            interval_list: ['B','B#'],

            progress1: 54,
            progress2: 0,


            // Play Contrl Box
            play: true,
            pause: false,
            current_time: 60,
            audio_time: 240,
            
            setting_music1: [
                {
                    title: "음악",
                    value: 50,
                },
                {
                    title: "멜로디",
                    value: 50,
                },
                {
                    title: "마이크",
                    value: 50,
                },
                {
                    title: "에코볼륨",
                    value: 50,
                },
                {
                    title: "에코시간",
                    value: 50,
                },
            ],
            setting_music2: [
                {
                    title: "템포",
                    value: 0,
                },
                {
                    title: "키",
                    value: 0,
                },
                {
                    title: "남/여",
                    value: 0,
                }
            ],

            
            setting_inst: [
                {
                    title: "스트링",
                    value: 50,
                },
                {
                    title: "브라스",
                    value: 50,
                },
                {
                    title: "피아노",
                    value: 50,
                },
                {
                    title: "기타",
                    value: 50,
                },
                {
                    title: "베이스",
                    value: 50,
                },
                {
                    title: "드럼",
                    value: 50,
                },
            ],

            setting_sheet_main: {
                active: true,
                list: ["멜로디","타이틀1","타이틀2"],
                selected_idx: 0,
                octave: 0,
                transpo: 0,
            },
            setting_sheet_sub: {
                active: true,
                list: ["스트링","브라스","피아노"],
                selected_idx: 0,
                octave: 0,
                transpo: 0,
            },

            setting_sheet_subtitle: {
                selected_idx: 0,
                list: ["가사","타이틀1"],
            },
            setting_sheet_note_color: true,
            setting_sheet_metronome: false,
            setting_sheet_progress: true,


            // front ui 
            select_pitch_mode: false,
            select_interval_mode: false,
            screen_ratio_vertical: true,

                // Play Contrl Box
            setting_open: false,
            status_open: false,
            lyric : [],
            count : '',
            lyric_color : '',
            lyric2 : [],
            lyric2_color : '',
            status:{
                connect_mic:false,
                repeat:0,
                lyric:false,

                // 구간반복, 구간반복 횟수
                repeatAB:0,
                repeatCount:0,

                rate:false,
                base:false,
                sheet:false,
                slow:false,
            },
            noAux:false,
            setting_tab_idx: 0,
            play_code : this.$route.params.code,
            code : '',
            type : '',
            nation : '',
            tempo : 0,
            start_time : '',
            lyric_list : [],
            tick_line : [],
            tick : '',
            line_tick : [],
            line_idx : '',
            line2_idx : '',
            line2_tick : [],
            line : '',
        }
    },
    methods:{
        
        ClickStart(){
            this.guide=false
            this.Init()
        },
        GetPlayInfo(){
            const play_code = this.play_code;
            
            const body = {play_code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/GetPlayInfo',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            
                            this.artist = body.info.artist;
                            this.name = body.info.title;
                            this.favorite = body.favorite;
                            
                            this.code = body.info.code;
                            this.nation = body.info.nation;
                            this.type = body.info.song_type
                            
                            if(this.showModal){
                                this.guide = this.showModal;
                            }else{
                                this.Init();

                            }
                        }
                    }
                }
            );
        },
        PlaySong(){
            const play_code = this.play_code;
            
            const body = {play_code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/PlaySong',{req});
        },
        ChangeScore(score){
            this.song_score = score;
        },
        CheckLyric(index){
            if(this.line_idx !=''){
                if(this.line ==1){
                    if(index > this.line_idx){
                        return "front_str"
                    }else{
                        return "back_str";
                    }
                }
                
            }else{
                return "front_str"
            }
            
        },
        CheckLyric2(index){
            if(this.line2_idx !=''){
                if(this.line ==2){
                    if(index > this.line2_idx){
                        return "front_str"
                    }else{
                        return "back_str";
                    }
                }
                
            }else{
                return "front_str"
            }
            
        },
        ChangeLyric(lyric,tick){
            
            const lyric_info = lyric.split('');
            this.line_idx = '';
            this.line_tick = [];
            let tick_lines = tick.replace('[', '');
            tick_lines = tick_lines.replace(']', '');
            let tick_list = tick_lines.split(',');
            
            for (const [index,el] of lyric_info.entries()) {
                if(el ==' '){
                    tick_list.splice(index,0, '-');
                }
            }
            this.lyric = lyric_info;
            this.line_tick = tick_list;
            
        },

        ChangeLyricSecond(lyric,tick){
            const lyric_info = lyric.split('');
            this.line2_idx = '';
            this.line2_tick = [];
            let tick_lines = tick.replace('[', '');
            tick_lines = tick_lines.replace(']', '');
            let tick_list = tick_lines.split(',');

            for (const [index,el] of lyric_info.entries()) {
                if(el ==' '){
                    tick_list.splice(index,0, '-');
                }
            }
            this.lyric2 = lyric_info;
            this.line2_tick = tick_list;
            
        },
        ChangLyricLine(){
            
            this.line = 1;
        },
        ChangLyricLineSecond(){
            
            this.line = 2;
        },
        
        SetLyricColor(color){
            if(color =='W'){
                this.lyric_color = 0;
            }else if(color =='G'){
                this.lyric_color = 100;
            }
            
        },

        SetLyricColorSecond(color){
            if(color =='W'){
                this.lyric2_color = 0;
            }else if(color =='G'){
                this.lyric2_color = 100;
            }
            
        },
        lyricLinesEnd(){
            this.lyric ="종료";
            if(this.play_song ==true){
                this.play_song = false;
                const song_score = this.song_score;
                const play_code = this.play_code;
                const end_time = new Date();
                const difference = end_time - this.start_time;
                let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((difference % (1000 * 60)) / 1000);


                const time = `${minutes}:${seconds}`;

                this.$router.replace({path : `/play-score/${play_code}/${song_score}/${time}`})
            }
            
        },
        TempoInfo(value){
            this.tempo = value;
        },
        PitchInfo(value){
            this.pitch = value;
        },
        ScoreTotal(total){
            this.song_score = total;
        },
        SetMic(status){
            if(status =='Y'){
                this.mic = true;
            }else if(status =='N'){
                this.mic = false
            }
        },
        ConnectMic(){
            
            if( /Android/i.test(navigator.userAgent)) {
                try{
                    HybridApp.ConnectMic();
                } catch (error) {
                    console.log(error);
                }
            }
        },
        ClickPitch(pitch){
            if(pitch =='U'){
                if( /Android/i.test(navigator.userAgent)) {
                    try{
                        HybridApp.PitchUp();
                    } catch (error) {
                        console.log(error);
                    }
                }
            }else if(pitch =='D'){
                if( /Android/i.test(navigator.userAgent)) {
                    try{
                        HybridApp.PitchDown();
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
            this.select_pitch_mode = false;
        },
        SetLyric(info){
            setTimeout(() => {
                this.play_song = true;
            },3000)
            this.lyric_info = info;
        },
        ClickTempo(tempo){
            if(tempo =='U'){
                if( /Android/i.test(navigator.userAgent)) {
                    try{
                        HybridApp.TempoUp();
                    } catch (error) {
                        console.log(error);
                    }
                }
            }else if(tempo =='D'){
                if( /Android/i.test(navigator.userAgent)) {
                    try{
                        HybridApp.TempoDown();
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
            this.select_tempo_mode = false;
        },
        ClickPlay(){
            this.start_time = new Date();
            this.PlaySong()
            if( /Android/i.test(navigator.userAgent)) {
                try{
                    const number = this.code*1;
                    const type = this.type*1;
                    const nation = this.nation*1;
                    
                    HybridApp.PlaySongCall(number,type,nation);
                    
                    
                } catch (error) {
                    console.log(error);
                }
            }

            this.play = true;
        },
        ClickStop(){
            if( /Android/i.test(navigator.userAgent)) {
                try{
                    
                    HybridApp.Stop();
                } catch (error) {
                    console.log(error);
                }
            }

            this.play = false;
        },
        ClickPause(){
            if( /Android/i.test(navigator.userAgent)) {
                try{
                    
                    HybridApp.Pause();
                } catch (error) {
                    console.log(error);
                }
            }

            this.play = false;
        },
        
        Init(){
            if( /Android/i.test(navigator.userAgent)) {
                try{
                    HybridApp.Init();
                } catch (error) {
                    console.log(error);
                }
            }
        },
        InitSuccess(){
            
            if( /Android/i.test(navigator.userAgent)) {
                try{
                    
                    HybridApp.RequestAuth();
                } catch (error) {
                    console.log(error);
                }
            }
        },
        AuthSuccess(){
            
            setTimeout(() => {
                this.ClickPlay();
            }, 1000);
            
        },
        ChangeCountEnd(){
            this.count = 0;
            
        },
        ChangeCount(count){
            this.count = count;
        },
        ScreenChk(){
            let _w = window.innerWidth;
            let _h = window.innerHeight;

            if( _h >= _w ){
                this.screen_ratio_vertical = true;
            }else{
                this.screen_ratio_vertical = false;
            }
        },
        SetPlay() {
            this.song_info = '재생중'
        },
        SetPause() {
            this.song_info = '일시정지'
        },
        SetStop() {
            this.song_info = '멈춤'
        },
        SetTick(tick) {
            this.tick = tick;
            
            let tick_line;
            
            if(this.line ==1){
                tick_line = this.line_tick
            }else if(this.line ==2){
                tick_line = this.line2_tick
            }
            for(const [ index, el ] of tick_line.entries()) {
                
                if(el*1 <= tick*1){
                    
                    if(this.line ==1){
                        this.line_idx = index;
                    }else if(this.line ==2){
                        this.line2_idx = index;
                    }
                }
                
                
            }
            
        },
        
        playToggle(){
            this.play = !this.play;
            this.pause =!this.pause;
        },
        Jump(){
            this.current_time += 40;
        },
        favoriteFn(){
            const fav = this.favorite;
            const code = this.code;
            const nation = this.nation;
            const body = {code,nation};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/favChange',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            if(fav ==true){
                                this.favorite = false;
                            }else{
                                this.favorite = true;
                            }
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
            
        },
        ScreenChk(){
            let _w = window.innerWidth;
            let _h = window.innerHeight;

            if( _h >= _w ){
                this.screen_ratio_vertical = true;
            }else{
                this.screen_ratio_vertical = false;
            }
        },
        noMore(){
            this.guide = false;
            this.$store.commit('scoreGuide',false);
            this.Init();
        },
        connectAux(){
            // 이어폰 잭 연결하지 않은 경우, 팝업 true
            this.noAux = true;
            // 이어폰 잭 연결한 경우, status.connect_mic = true
            // this.status.connect_mic=!this.status.connect_mic
        },
        repeatSec(){
            if(this.status.repeatAB ===2){
                this.status.repeatAB = 0;
                this.status.slow=false;
            }else{
                this.status.repeatAB += 1
            }
        },
        repeatFn(){
            if(this.status.repeat === 3){
                this.status.repeat = -1;
            }
            this.status.repeat += 1;
        },
        openBtm(item,boolean){
            this.status.lyric=false;
            this.status.sheet=false;
            this.status.rate=false;
            this.status.base=false;
            if(!boolean){
                switch ( item ){
                    case 'sheet' : this.status.sheet = true;
                    break;
                    case 'base' :this.status.base = true;
                    break;
                    case 'rate' :this.status.rate = true;
                    break;
                    default: this.status.lyric = true;
                }
            }
        },
        SettingMusic2_Up(index){
            this.setting_music2[index].value++;
        },
        SettingMusic2_Down(index){
            this.setting_music2[index].value--;
        },
        SettingSheet_MainInst_Up(){
            if(this.setting_sheet_main.selected_idx < this.setting_sheet_main.list.length-1){
                this.setting_sheet_main.selected_idx++;
            }
        },
        SettingSheet_MainInst_Down(){
            if(this.setting_sheet_main.selected_idx > 0){
                this.setting_sheet_main.selected_idx--;
            }
        },
        SettingSheet_MainOctave_Up(){
            this.setting_sheet_main.octave++;
        },
        SettingSheet_MainOctave_Down(){
            this.setting_sheet_main.octave--;
        },
        SettingSheet_MainTranspo_Up(){
            this.setting_sheet_main.transpo++;
        },
        SettingSheet_MainTranspo_Down(){
            this.setting_sheet_main.transpo--;
        },
        
        SettingSheet_SubInst_Up(){
            if(this.setting_sheet_sub.selected_idx < this.setting_sheet_sub.list.length-1){
                this.setting_sheet_sub.selected_idx++;
            }
        },
        SettingSheet_SubInst_Down(){
            if(this.setting_sheet_sub.selected_idx > 0){
                this.setting_sheet_sub.selected_idx--;
            }
        },
        SettingSheet_SubOctave_Up(){
            this.setting_sheet_sub.octave++;
        },
        SettingSheet_SubOctave_Down(){
            this.setting_sheet_sub.octave--;
        },
        SettingSheet_SubTranspo_Up(){
            this.setting_sheet_sub.transpo++;
        },
        SettingSheet_SubTranspo_Down(){
            this.setting_sheet_sub.transpo--;
        },

        
        SettingSheet_SubSubtitle_Up(){
            if(this.setting_sheet_subtitle.selected_idx < this.setting_sheet_subtitle.list.length-1){
                this.setting_sheet_subtitle.selected_idx++;
            }
        },
        SettingSheet_SubSubtitle_Down(){
            if(this.setting_sheet_subtitle.selected_idx > 0){
                this.setting_sheet_subtitle.selected_idx--;
            }
        },
        ClosePage(){
            this.lyricLinesEnd()
            this.ClickStop()
            
        },
        CancelExit(){
            this.exit_popup =false;
        },
        CloseSong(){
            this.exit_popup =true;

        },
    },
    computed:{
        barColors() {
            const activeBars = Math.floor(this.micSensitivity / 20);
            return Array(5)
                .fill(null)
                .map((_, index) => {
                // 활성화된 막대는 흰색, 나머지는 회색
                return index < activeBars ? 'rgba(255, 255, 255, .8)' : 'rgba(0, 0, 0, 0.3)';
                });
        },
        showModal(){
            return this.$store.state.score_guide
        },
        favorite_list(){
            return this.$store.state.favorite_song
        }
    },
    created(){
        window.songview = this;
    },
    
    mounted(){
        if( /Android/i.test(navigator.userAgent)) {
            try{
                
                HybridApp.Pause();
            } catch (error) {
                console.log(error);
            }
        } 
        window.addEventListener('resize', this.ScreenChk);

        // 마이크 감도 확인용 임시코드
        // setInterval(() => {
        //     this.micSensitivity = Math.floor(Math.random() * 101);
        // }, 1500); 
        //
        
        this.$EventBus.$emit('HideHeader')
        this.$EventBus.$emit('HideNav')
        this.ScreenChk();
        this.GetPlayInfo();
        
        
    },
    beforeDestroy(){
        this.ClickStop();
        window.removeEventListener('resize', this.ScreenChk);
        this.$EventBus.$emit('ShowHeader')
        this.$EventBus.$emit('ShowNav')
    },
}
</script>

<style lang="scss" scoped>
    #PlayBox{
        display: flex;
        flex-grow: 1;
        min-height: 100vh;
        background-color: #333;

        &.vertical{
            flex-direction: column;

            .song_wrap{
                width: 100%;
                height: 50vh;
                // margin-bottom: 60px;
                position: relative;
                transition: height .3s;
                &.lyric{
                    height: calc(100vh - 130px);
                    &::before{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: block;
                        background-color: rgba(0,0,0,.5);
                        z-index: 2;
                    }
                }
                .song_box{
                    height: 100%;
                }
            }   
            .bottom_box{
                flex: 1;
            } 
        }
        &.horizontal{
            flex-direction: unset;   

            .song_wrap{
                width: 55%;
                &.lyric{
                    &::before{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: block;
                        background-color: rgba(0,0,0,.5);
                        z-index: 2;
                    }
                }
                .song_box{
                    flex: 1;
                    height: 100%;
                    max-height: calc(100vh - 60px);
                    overflow: hidden;
                }
    
                .play_contrl_box{
                    transform: translateY(0);
                }
            }  
            .bottom_box{
                flex: 1;
            }      
        }

        .song_box{
            background-image: url(../../assets/img/play_bg.png);
            background-size: cover;

            &::after{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                backdrop-filter: blur(30px);
                -webkit-backdrop-filter: blur(30px);
                z-index: 0;
            }

            &>div{
                z-index: 2;
            }

            .option_btn{
                min-width: 60px;
                text-align: center;
                font-size: 0.85em;
                padding: 2px 0 3px;
                position: relative;
                user-select: none;
                &.checked{
                    background-color: rgba(0, 0, 0, 0.5) !important;
                    color: #fff !important;
                }
                &.half-bg{
                    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.25) 50%) !important;
                }
            }
            
            .drop_down_box{
                .dimm{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 9;
                }

                .drop_down{
                    min-width: 60px;
                    background-color: #fff;
                    z-index: 10;
                }
            }

            .jump10_btn{
                height: 29px;
                user-select: none;
            }
            .jump_btn{
                width: 60px;
                height: 60px;
                line-height: 1.2;
                user-select: none;
            }

            .inner_content{
                .lyrics{
                    position: absolute;
                    font-size: 1.5em !important;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 100%;
                    font-weight: bold;
                    text-align: center;

                    .line_box{
                        user-select: none;
                        position: relative;

                        span{
                            position: relative;
                            white-space: pre;
                        }

                        .cover{
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            overflow: hidden;
                            max-width: 100%;
                        }
                    }
                }

                .back_str{
                    color: #fff;
                    text-shadow:     
                    -1px -1px 0 #000,
                    1px -1px 0 #000,
                    -1px 1px 0 #000,
                    1px 1px 0 #000;  
                }
                .front_str{
                    color: #333;
                    text-shadow:     
                    -1px -1px 0 #fff,
                    1px -1px 0 #fff,
                    -1px 1px 0 #fff,
                    1px 1px 0 #fff;  
                }
            }

        }


        .lyric .song_box::after{
            height: 100%;
            backdrop-filter: blur(0px) !important;
        }
        .bottom_box{
            width: 45%;
            position: relative;
            background-color: #555;

            .status_btn_list{
                position: fixed;
                z-index: 1;
                bottom: 0;
                height: 50px;
                .open_status{
                    position: absolute;
                    left: 10px;
                    border: none;
                }
                ul.status_list {
                    position: absolute;
                    transition: all .3s;
                    display: flex;
                    gap: 5px;

                    button{
                        padding: 6px 8px;
                        border: none;
                    }
                    .checked{
                        background-color: #78a6e9 !important;
                        color: #fff !important;
                    }
                    .half-bg{
                        background: linear-gradient(90deg, #78a6e9 50%, #fff 50%) !important;
                    }
                }
                >button:not(.vertical){
                    +ul.status_list{
                        left:-100vw;
                    }
                    +ul.status_list.open{
                        left: 50px;
                    }
                }
                >button.vertical{           
                    +ul.status_list {
                        left:55px;
                        bottom: -100vw;
                    }
                    +ul.status_list.open{
                        bottom: 12px;
                    }
                }
            }
            .bottom_box_item{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #333;
            }
        } 
        
        .repeat{
            width: 20px;
            height: 24px;
            display: block;
            &#repeat-none{
                background: url('../../assets/img/icon/repeat_sm.png') -20px 3px no-repeat;
            }
            &#repeat-all{
                background: url('../../assets/img/icon/repeat_sm.png') 0 3px no-repeat;
            }
            &#repeat-one{
                background: url('../../assets/img/icon/repeat_sm.png') -41px 3px no-repeat;
            }
            &#repeat-random{
                background: url('../../assets/img/icon/repeat_sm.png') -81px 3px no-repeat;
            }
            &#repeat-ab{
                background: url('../../assets/img/icon/repeat_sm.png') -61px 3px no-repeat;
            }
        }
        .play_contrl_box{
            height: 60px;
            transform: translateY(60px);

            input[type=range]{
                -webkit-appearance: unset;
                /* position: relative; */
                background-color: #555;
            }
            input[type=range] { 
                -webkit-appearance: none; 
                overflow: hidden; 
                background: #555; 
                border-radius: 0;
                height: 4px;
            } 
            input[type=range]::-webkit-slider-thumb { 
                -webkit-appearance: none;
                height: 0px;
                width: 0px;
                border-radius: 100%;    

            }
            #audio-runtime::-webkit-slider-thumb { 
                box-shadow: -800px 0 0 800px #2f76dd; 
            }
        }
    }

    .setting_box{
        z-index: 5;

        .line_tab li{
            border-bottom: none;

            &.on{
                color: #78a6e9;
                font-weight: bold;
            }
        }

        .content{
            width: 100%;
            max-width: 320px;

            .body{
                height: fit-content;
                max-height: 80vh;
                overflow: auto;


                .setting_music_box{
                    .list1{
                        li{
                            width: 20%;
                        }
                    }
                }

                .setting_inst_box{
                    .list1{
                        li{
                            width: 16.666%;
                        }
                    }
                }
                
                input[type=range]{
                    -webkit-appearance: none;
                    writing-mode: bt-lr; /* IE */
                    -webkit-appearance: slider-vertical; /* Chromium */
                    -moz-appearance: slider-vertical;
                    width: 26px;
                    height: 175px;
                    padding: 0 5px;
                    accent-color : #78a6e9;
                }

                .arrow_box{
                    display: flex;
                    align-items: center;
                    .val_txt{
                        display: block;
                        min-width: 40px;

                        &.lg{
                            min-width: 60px;
                        }
                    }

                    .updwn_btn{
                        font-size: 80%;
                        border-radius: 100%;
                        background-color: rgba(255, 255, 255, 0.25);
                        width: 20px;
                        height: 20px;
                        line-height: 20px;

                        i{ margin-left: 1px; }

                        &.lg{
                            width: 22px;
                            height: 22px;
                            line-height: 23px;

                            i{ margin-left: 0px; }
                        }

                    }
                }
                
            }
        }
    }

.mic div {
  transition: background-color 0.3s ease;
}
</style>