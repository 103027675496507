<template>
    <div class="pb-nav">
        <TitleHeader :title="title" :link="'/my'" />
        <!-- <div class="d-flex flex-column">
            <div class="position-relative mx-auto" style="width:120px">
                <img :src="$store.state.my_info.avatar" alt="" class="ratio ratio-1x1 rounded-circle">
                <span class="position-absolute rounded-pill bg-secondary camera_btn fa-lg" @click="editImg()"><i class="fas fa-camera small"></i></span>
            </div>
        </div> -->
        
        <div class="container">
            <div>
                <div class="px-3">
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50">* 이메일</label>
                        <div class="input-group">
                            <input class="form-control border-gray-400 py-px-12" type="email" required placeholder="이메일" v-model="email" readonly>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50"> 닉네임</label>
                        <div class="input-group">
                                <input class="form-control border-gray-400 py-px-12" type="text" required placeholder="닉네임" v-model="nick" :readonly="CheckNickDisable()">
                                <div class="certi_btn btn border border-start-0 border-gray-400 flex-shrink-0 small  text-opacity-50" @click="CheckNickName()" v-if="nick_dupli_chk !=false">중복확인</div>
                                <div class="certi_btn btn border border-start-0 border-gray-400 flex-shrink-0 small  text-opacity-50" @click="ResetNickName()" v-if="nick_dupli_chk ==false">변경하기</div>
                            </div>
                        <div class="small feedback text-succsess" v-if="nick_dupli_chk===false">
                            <small>- 사용 가능한 닉네임입니다.</small>
                        </div>
                        <div class="small feedback text-danger" v-if="nick_dupli_chk===true">
                            <small>- 이미 존재하는 닉네임입니다. 다른 닉네임을 입력해주세요.</small>
                        </div>
                    </div>
                    
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50"> 성명</label>
                        <input class="form-control py-px-12" type="text" required placeholder="성명" v-model="name">
                    </div>

                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50"> 비밀번호</label>
                        <router-link to="/my/edit-password" class="btn border border-gray-400 py-px-12 d-block px-2 btn-sm">비밀번호 변경하기</router-link>
                    </div>

                    <hr class="my-5">

                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50"> 국가</label>
                        <select class="form-control py-px-12" type="text" required v-model="country">
                            <option v-for="(item,index) in country_list" :key="index" :value="item.code">{{item.name}}</option>
                            
                        </select>
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50">생년월일</label>
                        <input class="form-control py-px-12" type="date" placeholder="생년월일" v-model="birth">
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50">성별</label>
                        <div class="radio_btn_box">
                            <div class="form-check me-1">
                                <input class="form-check-input" type="radio" id="sex1" v-model="gender" value="M">
                                <label class="form-control py-px-12" for="sex1">남자</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" id="sex2" v-model="gender" value="F">
                                <label class="form-control py-px-12" for="sex2">여자</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50">핸드폰번호</label>
                        <div class="d-flex">
                            <!-- <select class="form-control py-px-12 me-1" style="width: 80px" type="text" required v-model="contact">
                                <option v-for="(item,index) in contact_list" :key="index" :value="item.value">{{item.name}}</option>
                            </select> -->
                            <input class="form-control py-px-12" type="number" placeholder="핸드폰번호" v-model="phone">
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50">직업</label>
                        <select class="form-control py-px-12" type="text" required v-model="job">
                            <option value="">선택하세요</option>
                            <option v-for="(item,index) in job_list" :key="index" :value="item.code">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="py-3">
                    <!-- <button class="btn btn-transparent my-3 px-2 text-muted fw-bold text-end" @click="showModal1 = true"><i class="far fa-sign-out-alt me-2"></i>로그아웃</button> -->
                    <router-link to="/my/opt-out" class="my-3 p-2 text-muted fs-px-14 ms-2">회원 탈퇴</router-link>
                </div>
                <div class=" w-100 p-3">
                    <button  class="btn bg-main py-px-12 w-100 text-white" @click="EditMyInfo()">저장하기</button>
                </div>
            </div>
        </div>

        <b-modal v-model="showModal1" size="sm" hide-header hide-footer centered>
            <div class="text-center">
                <div class="my-4">
                    <h4 class="mb-3">로그아웃</h4>
                    <p class="small">
                        로그아웃 하시겠습니까?
                    </p>
                </div>
                <div class="row mx-3 g-2 mt-3">
                    <div class="col col-5"><button class="btn btn-light w-100" @click="showModal1 = false">취소</button></div>
                    <div class="col col-7"><button class="btn btn-primary w-100" @click="$router.push('/')">확인</button></div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
const CryptoJS = require("crypto-js");
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title:'내 정보',
            nick: '',
            email: '',
            certifi_num: '',
            pw: '',
            pw2: '',

            country: '',
            name: '',
            birth: '',
            gender: '',
            phone: '',
            // contact2: '',
            job: '',

            // passport_num: '',
            // swift_code: '',
            // bank_name: '',
            // bank_account_num: '',

            // addr1: '',
            // addr2: '',
            // addr3: '',

            //------

            nick_dupli_chk: false,
            pw_match_chk: null,
            email_certifi_send_chk: false,
            email_certifi_chk: null,

            //------

            showModal1: false,
            
            country_list: [
                {name: '선택하세요', code: ''},
                {name: 'Republic of Korea (대한민국)', code: 'ko'},
                {name: 'Thailand (태국)', code: 'th'}
            ],
            contact_list: [
                {name: '선택', value: ''},
                {name: '+82', value: 82}
            ],
            job_list: [
                {name: '선택하세요', value: ''},
                {name: '직장인', value: 1},
                {name: '금융업', value: 2},
                {name: '공무원', value: 3},
                {name: '전문직', value: 4},
                {name: '자영업', value: 5},
                {name: '프리랜서', value: 6},
                {name: '기타', value: 7}
            ],
        }
    },
    computed:{
        alert_list(){
            return this.$store.state.alert_list
        },
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
        this.GetMyInfo()
        this.GetCommonJobList();
    },
    methods:{
        CheckNickDisable(){
            if(this.nick_dupli_chk==false){
                return true;
            }else{
                return false;
            }
        },
        ResetNickName(){
            this.nick = '';
            this.nick_dupli_chk = null;
        },
        GetCommonJobList(){
            
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/GetCommonJobList',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            this.job_list = body.list;
                            console.log(body)
                        }
                    }
                }
            );
        },
        
        CheckNickName(){
            const nick = this.nick;

            if(nick ==''){
                this.$alert("닉네임을 입력해주세요.")
                return false;
            }

            const body = {nick};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/mypage/CheckNickName',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.nick_dupli_chk = false;

                        }else if(res.data.code =="100"){
                            this.nick_dupli_chk = true;
                        }
                    }
                }
            );
        },
        EditMyInfo(){
            const nick = this.nick;
            const name = this.name;
            const phone = this.phone;
            const birth = this.birth;
            const gender = this.gender;
            const country = this.country;
            const job = this.job;

            const body = {nick,name,phone,gender,country,job,birth};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/mypage/EditMyInfo',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.$alert("변경하였습니다.");

                            this.$router.go();
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(async () => {
                                window.location.href = '/signin';
                            });
                        }
                    }
                }
            );

        },
        GetMyInfo(){

            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/mypage/GetMyInfo',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            
                            this.nick = body.nick;
                            this.email = body.email;
                            this.name = body.name;
                            this.phone = body.phone;
                            this.birth = body.birth;
                            this.job = body.job;
                            this.gender = body.gender;
                            this.country = body.country;
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(async () => {
                                window.location.href = '/signin';
                            });
                        }
                    }
                }
            );

        },
        
    },
    
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    },
}
</script>
<style lang="scss" scoped>

.camera_btn{
    bottom: 0;
    left: auto;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 33px;
    text-align: center;
}

.certi_btn{
    line-height: 35px;
    min-width: 90px;
    font-size: 14px;
}

.round_checkbox label{
    overflow: hidden;
}
.edit_btn{
>*{
    display: block;
    min-width: 100px;
    width: fit-content;
    margin-left:auto;
    margin-right: 15px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
}
</style>