<template>
    <div class="list pb-nav home_list_header_padding">
        <HomeListHeader />

        <BasicList :list="list" v-model="selectSong"  v-if="list.length>0" class="pb-res"></BasicList>
        <div class="empty_data" v-else>
            <p class="text-center py-5">
                즐겨찾는 노래가 없어요.
            </p>
        </div>
        
        <Nav @reserve-song="reserveSelectedSong"  @play-song="playSelectSong"></Nav>

        <div class="alert alert-success d-flex align-items-center position-fixed start-50 translate-middle-x shadow bottom-0 mb-2 max-w-px-768"  style="width:95%" role="alert"
            v-if="showReservationAlert">
            <i class="fas fa-check me-2"></i> 
            <div>
                노래를 예약하였습니다<br>
                {{ title }} - {{artist}}
            </div>
        </div>

        <div class="alert alert-success d-flex align-items-center position-fixed start-50 translate-middle-x shadow bottom-0 mb-2 max-w-px-768 alert-danger"  style="width:95%" role="alert"
            v-if="showSystemAlert">
            <i class="fas fa-check me-2"></i> 
            <div>
                {{showSystemMemo}}
            </div>
        </div>
    </div>
</template>

<script>
import BasicList from '@/components/common/BasicList.vue'
import Header from '@/components/common/Header.vue'
import Nav from '@/components/common/Nav.vue'
import HomeListHeader from '@/components/common/HomeListHeader.vue'
const CryptoJS = require("crypto-js");

export default {
    components:{
        BasicList,
        Header, Nav, HomeListHeader
    },
    data(){
        return{
            title:'즐겨찾기',
            selectSong:null,
            list :[],
            artist : '',
            title : '',
            showReservationAlert : false,
            showSystemAlert : false,
            showSystemMemo : '',
            login : this.$store.state.login,
        }
    },
    mounted(){
        this.GetFavList()
    },
    methods:{
        AddPlaySongReservation(){            

            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/AddPlaySongReservation',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);

                            const play_code = body.play_code;

                            this.$router.push({path : `/play-ready/${play_code}`});
                            
                        }else if(res.data.code =="100"){
                            this.showSystemMemo = "보유 이용권 티켓이 없습니다.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }else if(res.data.code =="300"){
                            
                            this.showSystemMemo = "하루의 사용할 티켓을 모두 소진하였습니다.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        CheckReservation(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/CheckReservation',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.AddPlaySongReservation();
                        }else if(res.data.code =="100"){
                            
                            this.showSystemMemo = "노래를 선택해주세요.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        CheckTicket(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/CheckTicket',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            if (this.selectSong) {
                                this.AddPlaySong();   
                            }else{
                                this.CheckReservation();
                            }
                        }else if(res.data.code =="100"){
                            
                            this.showSystemMemo = "보유 이용권 티켓이 없습니다.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }else if(res.data.code =="300"){
                            
                            this.showSystemMemo = "하루의 사용할 티켓을 모두 소진하였습니다.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        AddPlaySong(){
            const song = this.selectSong.item;
            const song_code = song.code;
            const nation = song.nation;
            const song_type = song.song_type;


            const body = {song_code,nation,song_type};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/AddPlaySong',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);

                            const play_code = body.play_code;

                            this.$router.push({path : `/play-ready/${play_code}`});
                            
                        }else if(res.data.code =="100"){
                            
                            this.showSystemMemo = "보유 이용권 티켓이 없습니다.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }else if(res.data.code =="300"){
                            
                            this.showSystemMemo = "하루의 사용할 티켓을 모두 소진하였습니다.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        playSelectSong(){
            this.CheckTicket();

        },
        GetFavList(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/GetFavList',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            this.list = body.list;
                        }else if(res.data.code =="100"){
                            
                        }
                    }
                }
            );
        },
        GetFavList(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/GetFavList',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            this.list = body.list;
                        }else if(res.data.code =="100"){
                            
                        }
                    }
                }
            );
        },
        AddReservation(code,nation){
            const body = {code,nation};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/AddReservation',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.showReservationAlert = true;
                            setTimeout(() => {
                                this.showReservationAlert = false;
                            }, 2000);
                        }else if(res.data.code =="100"){
                            this.showSystemMemo="이미 예약된 곡입니다."
                            this.showSystemAlert = true
                            setTimeout(() => {
                                this.showSystemMemo=""
                                this.showSystemAlert = false
                            }, 2000);
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        reserveSelectedSong() {
            if(this.login){
                if (this.selectSong) {
                    const song = this.selectSong.item;
                    
                    this.artist = song.artist;
                    this.title = song.title;
                    
                    const code = song.code;
                    const nation = song.nation;
                    
                    this.AddReservation(code,nation);
                    this.selectSong = null;
                    
                    
                }
            }else{
                this.$router.push('/signin');
            }
        },
    }
}
</script>
<style lang="scss" scoped>


// .category {
//     padding-top: 15px;
//     .btn{
//         width: 55px;
//         height: 55px;
//         font-size: 19px;
//         padding: 5px !important;
//         font-weight: 600;
//         text-align: center;
//     }
// }
// .search_filter{
//     button{
//         border: none;
//         border-radius: 0;
//         background-color: rgb(40,40,40);
//         color: #939393;
//         &.active{
//             background-color: #1e1f21;
//             color: #fff;
//         }
//         &:nth-of-type(2){
//             border-right: 1px solid;
//             border-left: 1px solid;
//             border-color: inherit;
//         }
//     }
// }
// .search_input{
//     padding: 0 15px;
//     box-sizing: border-box;
// }
// .input-group{
//     z-index: 3;
//     //color: #000 !important;
//     height: 50px;
//     input{
//         border-color: inherit;
//     }
// }
// .input-autocomplete{
//     position: absolute;
//     left: 0;
//     border-radius: 0 0 18px 18px;
//     height: 270px;
//     width: 100%;
//     z-index: 2;
//     padding-top: 25px;
//     box-shadow: 0 0 3px black;
// }
// .input-group-text{
//     // color: #fff;
//     background-color: transparent;
//     border-color: inherit;
//     border: none;
//     color: #FF5700;
// }
// .basic_list{
//     height: calc(100vh - 236px);
//     overflow-y: auto;
// }

</style>