<template>
    <div class="search pb-nav home_list_header_padding">
        <HomeListHeader />
        <div class="search_bar position-fixed w-100 bg-dark px-3 py-2">
            <div class="input-group rounded-pill">
                <span class="input-group-text px-1" id="basic-addon1"><i class="far fa-search"></i></span>
                <input type="search" class="form-control bg-transparent text-white border-0" placeholder="검색어를 입력하세요" aria-label="Username" aria-describedby="basic-addon1" v-model="search" v-on:keyup.enter="searchList()">
                <span class="input-group-text px-1" @click="autocomplete=!autocomplete"><i class="far fa-caret-down" :class="{'fa-caret-up':autocomplete}"></i></span>
            </div>
            <!-- 검색 내력 -->
            <div class="input-autocomplete bg-dark" v-if="autocomplete">
                <ul v-if="search_history.length>0" class="py-2">
                    <li v-for="(item, index) in search_history" :key="index" class="d-flex justify-content-between py-2 mx-4  align-items-center text-muted">
                        <small class="" @click="setHistory(index)">{{ item.title }}</small>
                        <i class="fal fa-trash fa-sm" @click="deleteHistory(index)"></i>
                    </li>
                </ul>
                <div v-else class="d-flex align-items-center justify-content-center h-100 flex-column text-muted"><i class="fas fa-headphones-alt fa-4x mb-3 text-muted"></i>검색한 음악이 없습니다.</div>
            </div>
        </div>
        
        <div class="search_result" style="padding-top: 65px;">
            <!-- <div class="search_filter d-flex m-3 mb-0 border">
                <button class="btn text-center col-4" :class="{'active':search_filter===0}" @click="filterFn(0)">통합검색</button>
                <button class="btn text-center col-4" :class="{'active':search_filter===1}" @click="filterFn(1)">제목 검색</button>
                <button class="btn text-center col-4" :class="{'active':search_filter===2}" @click="filterFn(2)">가수 검색</button>
            </div> -->
            <div v-if="list.length>0">
                <BasicList :list="list" v-model="selectSong" class="pb-res"></BasicList>
            </div>
            <div class="empty_data py-3" v-else>
                <p class="text-center pt-5">
                    찾으시는 노래가 없나요?
                    <br>
                    가수, 제목을 정확히 입력하셨나요?
                </p>
            </div>
        </div>
        
        <div class="alert alert-success d-flex align-items-center position-fixed start-50 translate-middle-x shadow bottom-0 mb-2 max-w-px-768" :class="{'alert-danger':alertType==='C'}" style="width:95%" role="alert"
            v-if="showAlert">
            <i class="fas fa-check me-2"></i> 
            <div v-if="alertType2==='reservation'">
                {{alertType==='C'?'노래를 취소하였습니다':'노래를 예약하였습니다'}}
            </div>
            <div v-else>
                {{alertType==='C'?'즐겨찾기에서 삭제되었습니다':'즐겨찾기에서 추가되었습니다'}}
            </div>
        </div>

        <div class="alert alert-success d-flex align-items-center position-fixed start-50 translate-middle-x shadow bottom-0 mb-2 max-w-px-768"  style="width:95%" role="alert"
            v-if="showReservationAlert">
            <i class="fas fa-check me-2"></i> 
            <div>
                노래를 예약하였습니다<br>
                {{ title }} - {{artist}}
            </div>
        </div>

        <div class="alert alert-success d-flex align-items-center position-fixed start-50 translate-middle-x shadow bottom-0 mb-2 max-w-px-768 alert-danger"  style="width:95%" role="alert"
            v-if="showSystemAlert">
            <i class="fas fa-check me-2"></i> 
            <div>
                {{showSystemMemo}}
            </div>
        </div>

        <Nav @reserve-song="reserveSelectedSong"  @play-song="playSelectSong"></Nav>
    </div>
</template>

<script>
import BasicList from '@/components/common/BasicList.vue'
import Reservation from '@/components/common/Reservation.vue'
import TitleHeader from '@/components/common/TitleHeader.vue'
import HomeListHeader from '@/components/common/HomeListHeader.vue'

import Nav from '@/components/common/Nav.vue'
const CryptoJS = require("crypto-js");

export default {
    components:{
        Nav,
        BasicList, Reservation, TitleHeader, HomeListHeader
    },
    data(){
        return{
            selectSong:null,
            showAlert: false,
            alertType:'',
            alertType2:'',
            search_filter:0,
            list : [],
            search:'',
            autocomplete:false,
            search_history:[],
            reservation : {},
            login : this.$store.state.login,
            title : this.title,
            artist : this.artist, 
            showReservationAlert : false,
            showSystemAlert : false,
            showSystemMemo : '',

        }
    }, 
    mounted(){
        this.GetSearchList();
        this.GetSearchHistory();
    },
    methods:{
        AddPlaySongReservation(){            

            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/AddPlaySongReservation',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);

                            const play_code = body.play_code;

                            this.$router.push({path : `/play-ready/${play_code}`});
                            
                        }else if(res.data.code =="100"){
                            this.showSystemMemo = "보유 이용권 티켓이 없습니다.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }else if(res.data.code =="300"){
                            this.showSystemMemo = "하루의 사용할 티켓을 모두 소진하였습니다.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        CheckReservation(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/CheckReservation',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.AddPlaySongReservation();
                        }else if(res.data.code =="100"){
                            
                            this.showSystemMemo = "노래를 선택해주세요.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        CheckTicket(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/CheckTicket',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            if (this.selectSong) {
                                this.AddPlaySong();   
                            }else{
                                this.CheckReservation();
                            }
                        }else if(res.data.code =="100"){
                            this.showSystemMemo = "보유 이용권 티켓이 없습니다.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }else if(res.data.code =="300"){
                            
                            this.showSystemMemo = "하루의 사용할 티켓을 모두 소진하였습니다.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        AddPlaySong(){
            const song = this.selectSong.item;
            const song_code = song.code;
            const nation = song.nation;
            const song_type = song.song_type;
            

            const body = {song_code,nation,song_type};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/AddPlaySong',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);

                            const play_code = body.play_code;

                            this.$router.push({path : `/play-ready/${play_code}`});
                            
                        }else if(res.data.code =="100"){
                            
                            this.showSystemMemo = "보유 이용권 티켓이 없습니다.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        }else if(res.data.code =="300"){
                            
                            this.showSystemMemo = "하루의 사용할 티켓을 모두 소진하였습니다.";
                            this.showSystemAlert = true;
                            setTimeout(() => {
                                this.showSystemMemo = '';
                                this.showSystemAlert = false;
                            }, 2000);
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        playSelectSong(){
            this.CheckTicket();
            
        },
        GetSearchHistory(){
            const history = this.$cookies.get('search_list');
            if(history !=null){
                this.search_history = history;
            }
        },
        searchList(){
            const history = this.$cookies.get('search_list');
            const search  = this.search;
            let after = [];
            const info = {title : search};
            if(history ==null){
                let list = [];
                this.$cookies.set('search_list',list);
                after = list;
            }else{
                const temp = history;

                let list  = [];
                
                list.push(info);
                
                for (var i=0;i<history.length;i++) {
                    if(i < 4){
                        const title = history[i].title;
                        list.push({title})
                    }
                    
                    
                }
                
                this.$cookies.set('search_list',list);

                after = list;
            }
            this.search_history = after;
            
            this.GetSearchList();
        },
        setHistory(index){
            const item = this.search_history[index];
            this.search = item.title;
            this.autocomplete = false;
            this.searchList();
        },
        deleteHistory(index){
            this.search_history.splice(index,1)
            this.$cookies.set('search_list',this.search_history);
        },
        GetSearchList(){
            const search = this.search;
            const body = {search};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/SearchList',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            this.list = body.list;
                        }
                    }
                }
            );            

        },
        AddReservation(code,nation){
            const body = {code,nation};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/AddReservation',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.autocomplete = false;
                        this.loading = false;
                        if(res.data.code =="200"){
                            
                            
                            this.showReservationAlert = true;
                            setTimeout(() => {
                                this.showReservationAlert = false;
                            }, 2000);
                            // this.$router.push('/frequent');
                        }else if(res.data.code =="100"){
                            this.showSystemMemo="이미 예약된 곡입니다."
                            this.showSystemAlert = true
                            setTimeout(() => {
                                this.showSystemMemo=""
                                this.showSystemAlert = false
                            }, 2000);
                        } else if (res.data.code == '9999') {
                            this.$store.dispatch('SETLOGOUT').then(() => {
                                this.$router.push({ path: '/signin' });
                            });
                        }
                    }
                }
            );
        },
        reserveSelectedSong() {
            if(this.login){
                if (this.selectSong) {
                    const song = this.selectSong.item;
                    
                    this.artist = song.artist;
                    this.title = song.title;
                    
                    const code = song.code;
                    const nation = song.nation;
                    
                    this.AddReservation(code,nation);
                    this.selectSong = null;
                    
                    
                }
            }else{
                this.$router.push('/signin');
            }
            
        },
    },
    destroyed(){
    }
}
</script>

<style lang="scss" scoped>
.search_bar{
    /* top: 150px;*/
    width: 100%;
    max-width: 768px;
}
</style>