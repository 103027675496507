<template>
    <div class="mh-100vh">
        <TitleHeader :title="title" />
        
        <div class="d-flex justify-content-around border px-2 mx-3 mb-4 rounded shadow-sm">
            <ul class="w-100 fs-px-15">
                <li>
                    <div class="d-flex py-3 ps-2 pe-1 justify-content-between align-items-center ">
                        <div>자동로그인</div>
                        <div>
                            <div class="form-check form-switch p-0">
                                <input class="form-check-input py-2" type="checkbox" role="switch" v-model="auto_login" @change="ChangeAutoLogin()">
                            </div>
                        </div>
                    </div>
                </li>
                
                <!-- <li>
                    <div class="d-flex py-3 ps-2 pe-1 justify-content-between align-items-center">
                        <div>다크모드</div>
                        <div>
                            <div class="form-check form-switch p-0">
                                <input class="form-check-input py-2" type="checkbox" role="switch" v-model="input_1">
                            </div>
                        </div>
                    </div>
                </li> -->

                <!-- <li>
                    <div class="d-flex py-3 ps-2 pe-1 justify-content-between align-items-center border-bottom">
                        <div>푸시 알림</div>
                        <div>
                            <div class="form-check form-switch p-0">
                                <input class="form-check-input py-2" type="checkbox" role="switch" v-model="input_1">
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="d-flex py-3 ps-2 pe-1 justify-content-between align-items-center border-bottom">
                        <div>신곡 업데이트 알림</div>
                        <div>
                            <div class="form-check form-switch p-0">
                                <input class="form-check-input py-2" type="checkbox" role="switch" v-model="input_2">
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="d-flex py-3 ps-2 pe-1 justify-content-between align-items-center border-bottom">
                        <div>자동 녹음</div>
                        <div>
                            <div class="form-check form-switch p-0">
                                <input class="form-check-input py-2" type="checkbox" role="switch" v-model="input_3">
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="d-flex py-3 ps-2 pe-1 justify-content-between align-items-center">
                        <div>이벤트/공지사항 알림</div>
                        <div>
                            <div class="form-check form-switch p-0">
                                <input class="form-check-input py-2" type="checkbox" role="switch" v-model="input_4">
                            </div>
                        </div>
                    </div>
                </li> -->
            </ul>
        </div>

        <div class="d-flex justify-content-around border px-2 mx-3 rounded shadow-sm">
            <ul class="w-100 fs-px-15">
                <li @click="ClickTerms()">
                    <div class="d-flex py-2 ps-2 justify-content-between align-items-center border-bottom">
                        <div>약관 및 정책</div>
                        <button class="btn btn-transparent"><i class="far" :class="{'fa-chevron-down':!show_terms, 'fa-chevron-up':show_terms}"></i></button>
                    </div>
                </li>
                <div v-if="show_terms">
                    <li v-for="(item,index) in terms_list" :key="index">
                        <router-link :to="`/my/terms/${item.code}`" class="d-flex py-2 ps-2 justify-content-between align-items-center border-bottom">
                            <div> <span class="fw-bold text-body text-opacity-50 me-2">└</span> {{item.title}}</div>
                            <button class="btn btn-transparent"><i class="far fa-chevron-right"></i></button>
                        </router-link>
                    </li>
                </div>
                <li>
                    <router-link to="#" class="d-flex p-3 ps-2 justify-content-between align-items-center">
                        <div>버전정보</div>
                        <div>v1.0.0</div>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
const CryptoJS = require("crypto-js");
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title:'설정',
            auto_login : 'N',
            input_0: true,
            input_1: false,

            terms_list: [
                {
                    idx: 1,
                    title: "이용자 약관 정보",
                },
                {
                    idx: 2,
                    title: "개인정보 처리 방침",
                },
                {
                    idx: 3,
                    title: "E-mail 및 SMS 광고성 정보",
                },
            ],

            // front_ui
            show_terms: this.$store.state.show_terms,
        }
    },

    mounted(){
        this.$EventBus.$emit('HideHeader')
        this.$EventBus.$emit('HideNav')
        this.GetTermList();
        if(this.$store.state.auto_login =='N'){
            this.auto_login = false;
        }else if(this.$store.state.auto_login =='N'){
            this.auto_login = true;
        }
    },
    methods:{
        GetTermList(){

            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/mypage/GetTermList',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            this.terms_list = body.list
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(async () => {
                                window.location.href = '/signin';
                            });
                        }
                    }
                }
            );

        },

        ChangeAutoLogin(){

            let auto_login = 'N';
            if(this.auto_login){
                auto_login = 'Y'
            }
            
            const body = {auto_login};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/mypage/ChangeAutoLogin',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            const auto_token = body.login_token;
                            if(auto_login =='Y'){
                                const mem_info = { auto_login,auto_token};
                                this.$store.dispatch('SETAUTOLOGIN', { mem_info })
                            }else{
                                const mem_info = { auto_login,auto_token};
                                this.$store.dispatch('SETAUTOLOGIN', { mem_info })
                            }
                            // this.terms_list = body.list
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(async () => {
                                window.location.href = '/signin';
                            });
                        }
                    }
                }
            );
        },
        ClickTerms(){
            if(this.show_terms ==true){
                this.show_terms = false;
            }else{
                this.show_terms = true;
            }
            
        }    
    },
    destroyed(){
        
        this.$EventBus.$emit('ShowHeader')
        this.$EventBus.$emit('ShowNav')
    }
}
</script>

<style>

</style>