<template>
    <div class="pb-5 mb-5">
        <TitleHeader :title="title" />

        <div class="container">
            <div>
                <div class="px-3">
                    <div class="form-group">
                        <label class="small text-body text-opacity-50 ms-1">답변 받으실 이메일</label>
                        <div class="input-group">
                            <input class="form-control py-px-12 border" type="text" required placeholder="답변 받으실 이메일" v-model="email">
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50 ms-1">문의 제목</label>
                        <input class="form-control py-px-12" type="text" required placeholder="문의 제목" v-model="tit">
                    </div>
                    <div class="form-group mt-3">
                        <label class="small text-body text-opacity-50 ms-1">문의 내용</label>
                        <textarea class="post_desc border rounded p-3 w-100" rows="10" v-model="memo" maxlength="1000">
                        </textarea>
                        <div class="d-flex"><span class="ms-auto small">{{memo.length}}/1000</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="px-3 mt-5">
            <button class="btn bg-main text-white py-px-12 mb-3 w-100" @click="Submit()" :disabled="memo.length < 10">
                문의하기
            </button>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
const CryptoJS = require("crypto-js");
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '1:1 문의하기',

            email: '',

            tit: '',
            memo: '',

        }
    },
    methods:{
        Submit(){
            const title = this.tit;
            const memo = this.memo;
            const email = this.email;

            const body = {email,title,memo};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/cs/AddInquiry',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.$alert("정상적으로 제출하였습니다.");
                            
                            this.$router.push(`/cs/inquiry/list`)
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(async () => {
                                window.location.href = '/signin';
                            });
                        }
                    }
                }
            );
            
        }
    },
    
    mounted(){
        // this.email = this.$store.state.my_info.email;
        this.$EventBus.$emit('HideHeader')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
    }
}
</script>

<style lang="scss" scoped>
    .post_desc{
        height: calc(100vh - 520px);
        min-height: 250px;
    }
</style>