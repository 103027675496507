import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/common/Search.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/home"
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  // 리스팅
  {
    path:'/favorite',
    name:'Favorite',
    component: () => import(/* webpackChunkName: "list" */ '../views/list/Favorite.vue'),
  },
  {
    path:'/list/trend',
    name:'Trend',
    component: () => import(/* webpackChunkName: "list" */ '../views/list/Trend.vue'),
  },
  {
    path:'/list/recent',
    name:'Recent',
    component: () => import(/* webpackChunkName: "list" */ '../views/list/Recent.vue'),
  },
  {
    path:'/frequent',
    name:'Frequent',
    component: () => import(/* webpackChunkName: "list" */ '../views/list/Frequent.vue'),
  },
  {
    path:'/list/idol',
    name:'Idol',
    component: () => import(/* webpackChunkName: "list" */ '../views/list/Idol.vue'),
  },
  {
    path:'/list/history',
    name:'History',
    component: () => import(/* webpackChunkName: "list" */ '../views/list/History.vue'),
  },
  {
    path:'/list/kids',
    name:'Kids',
    component: () => import(/* webpackChunkName: "list" */ '../views/list/Kids.vue'),
  },
  {
    path:'/list/7080',
    name:'Frequent7080',
    component: () => import(/* webpackChunkName: "list" */ '../views/list/Frequent7080.vue'),
  },
  {
    path:'/record',
    name:'Record',
    component: () => import(/* webpackChunkName: "list" */ '../views/record/Record.vue'),
  },

  // 로그인&회원가입
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/SignIn.vue'),
  },
  // {
  //   path: '/signin-email',
  //   name: 'SignInEmail',
  //   component: () => import(/* webpackChunkName: "sign" */ '../views/sign/SignInEmail.vue')
  // },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/SignUp.vue')
  },
  {
    path: '/social-login',
    name: 'SocialLogin',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/SocialLogin.vue')
  },
  {
    path: '/certify',
    name: 'Certify',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/Certify.vue')
  },
  {
    path: '/signup-intro',
    name: 'SignUpIntro',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/SignUpIntro.vue')
  },
  {
    path: '/find-email',
    name: 'FindEmail',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/FindEmail.vue')
  },
  {
    path: '/find-pw',
    name: 'FindPw',
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/FindPw.vue')
  },

  // 노래화면
  {
    path: '/play/:code',
    name: 'Play',
    component: () => import(/* webpackChunkName: "play" */ '../views/play/PlaySong.vue')
  },
  {
    path: '/play-ready/:code',
    name: 'PlayReady',
    component: () => import(/* webpackChunkName: "play" */ '../views/play/PlayReady.vue')
  },
  {
    path: '/play-score/:code/:score/:time',
    name: 'PlayScore',
    component: () => import(/* webpackChunkName: "play" */ '../views/play/PlayScore.vue')
  },

  // 고객센터
  {
    path: '/cs/home',
    name: 'CsHome',
    component: () => import(/* webpackChunkName: "cs" */ '../views/cs/Home.vue')
  },
  {
    path: '/cs/inquiry/write',
    name: 'CsInquiryWrite',
    component: () => import(/* webpackChunkName: "cs" */ '../views/cs/InquiryWrite.vue')
  },
  {
    path: '/cs/inquiry/list',
    name: 'CsInquiryList',
    component: () => import(/* webpackChunkName: "cs" */ '../views/cs/InquiryList.vue')
  },
  {
    path: '/cs/faq',
    name: 'CsFaq',
    component: () => import(/* webpackChunkName: "cs" */ '../views/cs/Faq.vue')
  },
  {
    path: '/cs/notice',
    name: 'CsNotice',
    component: () => import(/* webpackChunkName: "cs" */ '../views/cs/Notice.vue')
  },
  {
    path: '/cs/notice/:code',
    name: 'CsNoticeDetail',
    component: () => import(/* webpackChunkName: "cs" */ '../views/cs/NoticeDetail.vue')
  },
  {
    path: '/cs/event',
    name: 'CsEvent',
    component: () => import(/* webpackChunkName: "cs" */ '../views/cs/Event.vue')
  },
  {
    path: '/cs/event/:idx',
    name: 'CsEventDetail',
    component: () => import(/* webpackChunkName: "cs" */ '../views/cs/EventDetail.vue')
  },

  // 내정보
  {
    path: '/my',
    name: 'MyHome',
    component: () => import(/* webpackChunkName: "my" */ '../views/my/Home.vue')
  },
  {
    path: '/my/coupon-list',
    name: 'CouponList',
    component: () => import(/* webpackChunkName: "my" */ '../views/my/CouponList.vue')
  },
  {
    path: '/my/coupon-add',
    name: 'CouponAdd',
    component: () => import(/* webpackChunkName: "my" */ '../views/my/CouponAdd.vue')
  },
  {
    path: '/my/song-list',
    name: 'SongList',
    component: () => import(/* webpackChunkName: "my" */ '../views/my/SongList.vue')
  },
  {
    path: '/my/edit',
    name: 'MyInfoEdit',
    component: () => import(/* webpackChunkName: "my" */ '../views/my/MyInfoEdit.vue')
  },
  {
    path: '/my/edit-password',
    name: 'ChangePassWord',
    component: () => import(/* webpackChunkName: "my" */ '../views/my/ChangePassword.vue')
  },
  {
    path: '/alert',
    name: 'Alert',
    component: () => import(/* webpackChunkName: "my" */ '../views/my/Alert.vue')
  },
  {
    path: '/alert/:idx',
    name: 'AlertDetail',
    component: () => import(/* webpackChunkName: "my" */ '../views/my/AlertDetail.vue')
  },
  {
    path: '/my/opt-out',
    name: 'OptOut',
    component: () => import(/* webpackChunkName: "my" */ '../views/my/OptOut.vue')
  },
  {
    path: '/my/setting',
    name: 'Setting',
    component: () => import(/* webpackChunkName: "my" */ '../views/my/Setting.vue')
  },
  {
    path: '/my/terms/:code',
    name: 'TermsDetail',
    component: () => import(/* webpackChunkName: "my" */ '../views/my/TermsDetail.vue')
  },

  // 상점
  {
    path: '/shop',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "my" */ '../views/shop/Shop.vue')
  },






  // =========================== NEW ============================

  // My 자산
  {
    path: '/my-assets/user',
    name: 'MyAssetsUser',
    component: () => import(/* webpackChunkName: "my-assets" */ '../views/my-assets/MyAssetsUser.vue')
  },
  
  {
    path: '/my-assets/user-list',
    name: 'MyAssetsUserList',
    component: () => import(/* webpackChunkName: "my-assets" */ '../views/my-assets/MyAssetsUserList.vue')
  },
  {
    path: '/my-assets/smc',
    name: 'MyAssetsSMC',
    component: () => import(/* webpackChunkName: "my-assets" */ '../views/my-assets/MyAssetsSMC.vue')
  },
  {
    path: '/my-assets/smc-list',
    name: 'MyAssetsSMCList',
    component: () => import(/* webpackChunkName: "my-assets" */ '../views/my-assets/MyAssetsSMCList.vue')
  },
  {
    path: '/my-assets/smc-charge',
    name: 'MyAssetsSMCCharge',
    component: () => import(/* webpackChunkName: "my-assets" */ '../views/my-assets/MyAssetsSMCCharge.vue')
  },




  // mission bank
  {
    path: '/mission-bank',
    name: 'MissionBankHome',
    component: () => import(/* webpackChunkName: "mission-bank" */ '../views/mission-bank/Home.vue')
  },

  // ad mission
  {
    path: '/ad-mission',
    name: 'AdMissionHome',
    component: () => import(/* webpackChunkName: "mission-bank" */ '../views/missions/ad-mission/Home.vue'),
  },
  {
    path: '/ad-mission/:idx',
    name: 'AdMissionContent',
    component: () => import(/* webpackChunkName: "mission-bank" */ '../views/missions/ad-mission/Content.vue'),
  },

  // servey mission
  {
    path: '/servey-mission',
    name: 'ServeyMissionHome',
    component: () => import(/* webpackChunkName: "mission-bank" */ '../views/missions/survey-mission/Home.vue'),
    redirect: '/servey-mission/servey',
    children:[
      {
        path: '/servey-mission/servey',
        name: 'ServeyMissionServey',
        component: () => import(/* webpackChunkName: "mission-bank" */ '../views/missions/survey-mission/Servey.vue'),
      },
      {
        path: '/servey-mission/affiliate-card',
        name: 'ServeyMissionAffiliateCard',
        component: () => import(/* webpackChunkName: "mission-bank" */ '../views/missions/survey-mission/AffiliateCard.vue'),
      },
      {
        path: '/servey-mission/event',
        name: 'ServeyMissionEvent',
        component: () => import(/* webpackChunkName: "mission-bank" */ '../views/missions/survey-mission/Event.vue'),
      },
    ]
  },

  // notice bank
  {
    path: '/notice-bank',
    name: 'NoticeBankHome',
    component: () => import(/* webpackChunkName: "mission-bank" */ '../views/banks/notice-bank/Home.vue'),
    redirect: '/notice-bank/user',
    children:[
      {
        path: '/notice-bank/user',
        name: 'NoticeBankUser',
        component: () => import(/* webpackChunkName: "mission-bank" */ '../views/banks/notice-bank/User.vue')
      },
      {
        path: '/notice-bank/smc',
        name: 'NoticeBankSMC',
        component: () => import(/* webpackChunkName: "mission-bank" */ '../views/banks/notice-bank/SMC.vue')
      },
      {
        path: '/notice-bank/agency',
        name: 'NoticeBankAgency',
        component: () => import(/* webpackChunkName: "mission-bank" */ '../views/banks/notice-bank/Agency.vue')
      },
    ]
  },
  // business bank
  {
    path: '/business-bank',
    name: 'BusinessBankHome',
    component: () => import(/* webpackChunkName: "mission-bank" */ '../views/banks/business-bank/Home.vue')
  },
  // partner bank
  {
    path: '/partner-bank',
    name: 'PartnerBankHome',
    component: () => import(/* webpackChunkName: "mission-bank" */ '../views/banks/partner-bank/Home.vue')
  },
  // qna bank
  {
    path: '/qna-bank',
    name: 'QnaBankHome',
    component: () => import(/* webpackChunkName: "mission-bank" */ '../views/banks/qna-bank/Home.vue')
  },
  // shopping bank
  {
    path: '/shopping-bank',
    name: 'ShoppingBankHome',
    component: () => import(/* webpackChunkName: "mission-bank" */ '../views/banks/shopping-bank/Home.vue')
  },
  // tour bank
  {
    path: '/tour-bank',
    name: 'TourBankHome',
    component: () => import(/* webpackChunkName: "mission-bank" */ '../views/banks/tour-bank/Home.vue')
  },
  // game bank
  {
    path: '/game-bank',
    name: 'GameBankHome',
    component: () => import(/* webpackChunkName: "mission-bank" */ '../views/banks/game-bank/Home.vue')
  },
  // gift bank
  {
    path: '/gift-bank',
    name: 'GiftBankHome',
    component: () => import(/* webpackChunkName: "mission-bank" */ '../views/banks/gift-bank/Home.vue')
  },
  {
    path: '/gift-bank/won-gift',
    name: 'GiftBankWonGift',
    component: () => import(/* webpackChunkName: "mission-bank" */ '../views/banks/gift-bank/WonGift.vue')
  },
  {
    path: '/gift-bank/received-gift',
    name: 'GiftBankReceivedGift',
    component: () => import(/* webpackChunkName: "mission-bank" */ '../views/banks/gift-bank/ReceivedGift.vue')
  },


  // mypage
  {
    path: '/mypage',
    name: 'MypageHome',
    component: () => import(/* webpackChunkName: "mission-bank" */ '../views/mypage/Home.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return { x:0, y:0 }
  },
  base: process.env.BASE_URL,
  routes,
})

export default router
